<template>
  <div class="about">      
    <b-row>
      <b-col lg="6" offset-lg="2">
        <b-container class="mt-5">
          <b-row>            
            <b-col offset="2" class="pt-4">
              <b-card no-body class="card mb-5 overflow-hidden" style="background-color: transparent; border-color: transparent;">
                <b-row no-gutters>              
                  <b-col cols="" offset="" class="my-1">
                    <b-card-img :src='require("@/assets/ribbon-small.png")' style="width:50px"></b-card-img>
                  </b-col>
                  <b-col cols="" class="my-auto text-left">
                    <b-card-text><h2 style="color:#467EB7; text-align: left;">Sobre Nosotros</h2></b-card-text>
                  </b-col>
                </b-row>
              </b-card>               
            </b-col>            
          </b-row>      
          <b-row>
            <b-col>
              <div class="pt-3 pr-5 px-4">
                <p class="text">Porque no hay nada mas preciado tanto para los adultos como para nuestro Dios que la vida de nuestros niños. La Asociación Civil Leonardo Un milagro de esperanza nace en 2021 desde el corazón de muchos padres de familia pertenecientes a la comunidad religiosa del Templo Metodista Shalom de la ciudad de Chihuahua. Trabajando con la intención de ser un instrumento para mejorar la salud y la vida de los niños con padecimiento de cáncer así como dar soporte y consuelo a sus familias con las diferentes necesidades que se generan al navegar con este tipo de padecimientos.Siempre sirviendo con la esperanza de un milagro, sobre toda acción que se realiza; Dios nos inspira a trabajar en busca de un milagro, el milagro de la restauración, de la sanidad, de la paz y de la vida.</p>              
                <p class="text">El ejemplo de Leonardo y su familia nos deja un claro mensaje: En Dios tenemos esperanza de vida.  Nosotros nos dedicamos  a llevar esa esperanza a todos los niños con cáncer, supliendo medicamentos e insumos para sus tratamientos de la mejor manera posible.</p>             
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4">                
        <b-img :src='require("@/assets/About.png")' fluid-grow></b-img>        
      </b-col>
    </b-row>  
  </div>
</template>

<script>
export default {
  name:"About"
}
</script>
<style scoped>
.about{
  font-family: "Museo Slab";
  margin-bottom:-48px;
  background: url('~@/assets/background.png') 20% 70% no-repeat;
  background-size: 60%;
}
.text{
  text-align: right;
}
</style>