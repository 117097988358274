<template>
  <div id="story">
    <b-row>
      <b-col lg="6" offset-lg="2">
        <b-container class="mt-5">
          <b-row>            
            <b-col offset="2" class="pt-4">
              <b-card no-body class="card mb-5 overflow-hidden" style="border-color: transparent; background-color: transparent;">
                <b-row no-gutters>              
                  <b-col cols="" offset="" class="my-1">
                    <b-card-img :src='require("@/assets/ribbon-small.png")' style="width:50px"></b-card-img>
                  </b-col>
                  <b-col cols="" offset="" class="my-auto text-left">
                    <b-card-text><h2 style="color:#467EB7; text-align: left;">La historia de Leonardo</h2></b-card-text>
                  </b-col>
                </b-row>
              </b-card>               
            </b-col>            
          </b-row>      
          <b-row>
            <b-col>
              <div class="pt-3 pr-5 px-4">
                <p class="text">Leonardo fue diagnosticado con leucemia a los 4 años. En el 2014, después de 1 año de tratamiento con quimioterapia, requirió trasplante de medula ósea. Posterior a esta s le realizó un segundo transplante. En el 2017, a los 7 años de edad, Leo fallece por causa de metástasis.</p>              
                <p class="text">Durante los tres años de tratamientos que llevo este proceso, Leo fue un gran ejemplo de fortaleza y alegría la cual le trasmitía a todo aquel que le conocía, esto indudablemente fue muestra cada día de la grandeza de Dios manifestándose en su vida, ya que como familia entendimos que su vida tenía un propósito, el cual cumplió hasta el último día de su vida. Hoy sabemos que el propósito de Dios no concluyó, sino que con toda esa fortaleza y alegría continuamos con el propósito de salvación y de llevar esperanza a todo niño que pase por situación de Cáncer.</p>             
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4">                
        <b-img :src='require("@/assets/story.png")' fluid-grow></b-img>        
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name:"Historia"
}
</script>

<style scoped>
.text{
  text-align:right;
}
#story{
  margin-bottom:-48px;
  font-family: "Museo Slab";
  background: url('~@/assets/background.png') 20% 70% no-repeat;
  background-size: 60%;
}
</style>