<template>
  <div id="donations">
    <b-row>
      <b-col lg="6" offset-lg="2">
        <b-container class="mt-5">
          <b-row>            
            <b-col offset="2" class="pt-4">
              <b-card no-body class="card mb-5 overflow-hidden" style="background-color: transparent; border-color: transparent;">
                <b-row no-gutters>              
                  <b-col cols="" offset="" class="my-1">
                    <b-card-img :src='require("@/assets/ribbon-small.png")' style="width:50px"></b-card-img>
                  </b-col>
                  <b-col cols="" class="my-auto text-left">
                    <b-card-text><h2 style="color:#467EB7; text-align: left;">Donaciones</h2></b-card-text>
                  </b-col>
                </b-row>
              </b-card>               
            </b-col>            
          </b-row>      
          <b-row>
            <b-col>
              <div class="pt-3 pr-5 px-4">
                <p class="text">Depósito o transferencia bancaria</p>              
                <p class="text">Santander</p>              
                <p class="text">Número de cuenta</p> 
                <p class="text">65-50984158-7</p>                        
                <p class="text">CLABE</p>                        
                <p class="text">014150655098415875</p>                        
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4">                
        <b-img :src='require("@/assets/donations.png")' fluid-grow></b-img>        
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name:"Donaciones"
}
</script>

<style scoped>
.text{
  text-align:right;
}
#donations{
  margin-bottom:-48px;
  font-family: "Museo Slab";
    background: url('~@/assets/background.png') 20% 90% no-repeat;
  background-size: 50%;
}
</style>
