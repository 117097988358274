<template>
  <div id="donations">
    <b-row>
      <b-col lg="6" offset-lg="1">
        <b-container class="mt-5">
          <b-row>            
            <b-col offset="2" class="pt-4">
              <b-card no-body class="card mb-5 overflow-hidden" style="background-color: transparent; border-color: transparent;">
                <b-row no-gutters>              
                  <b-col cols="" offset="" class="my-1">
                    <b-card-img :src='require("@/assets/ribbon-small.png")' style="width:50px"></b-card-img>
                  </b-col>
                  <b-col cols="" class="my-auto text-left">
                    <b-card-text><h2 style="color:#467EB7; text-align: left;">Contacto</h2></b-card-text>
                  </b-col>
                </b-row>
              </b-card>               
            </b-col>            
          </b-row>      
          <b-row>
            <b-col>
              <div class="pt-3 pr-5 px-4">
                <p>Mándanos un mensaje para más información</p>                   
                <b-form class="form" ref="contactForm" id="contactForm">                  
                  <b-form-group id="input-group-2" label="Nombre" label-for="input-2" class="my-5">
                    <b-form-input
                      id="input-2"
                      v-model="name"
                      placeholder="José Pérez"
                      required
                      class="mt-2"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"
                    label="Correo"
                    label-for="input-1"                    
                    class="my-5"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="email"
                      type="email"
                      placeholder="ejemplo@correo.com"
                      class="mt-2"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-1"
                    label="Mensaje"
                    label-for="input-1"                    
                    class="my-5"
                  >
                    <b-form-textarea
                      id="textarea"
                      v-model="message"
                      placeholder="Escribe tu mensaje..."
                      rows="3"
                      max-rows="6"
                      class="mt-2"
                      required
                    ></b-form-textarea>
                  </b-form-group>                
                  <vue-recaptcha ref="recaptcha" sitekey="6Ld-fHEfAAAAAGcpT3TradM4qjJMJYqRnmslS7aa" @verify="onVerify"></vue-recaptcha>
                  <b-button class="mt-3" ref="sendButton" :disabled="recaptcha == false" id="send" style="color:white;" @click="sendEmail()">Enviar</b-button>                 
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col>                
        <GmapMap
          :center="{lat:28.63942199577297, lng:-106.09637048020218}"
          :zoom="16"
          map-type-id="terrain"
          style="width: 500px; height: 600px"
          class="mx-auto my-5"
        >
          <GmapMarker                        
            :position="{lat:28.63942199577297, lng:-106.09637048020218}"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindowTemplate({lat:28.63942199577297, lng:-106.09637048020218})"
          />
          <gmap-info-window :options="{maxWidth: 300}" :position="infoWindow.position" :opened="infoWindow.open" @closeclick="infoWindow.open = false">
            <div id="thediv">Leonardo Un Milagro De Esperanza AC</div>
          </gmap-info-window>
        </GmapMap>
      </b-col>
    </b-row>
    <b-row class="mx-5 py-5">
      <b-col lg="4">
        <b-card bg-variant="light" class="text-center card mb-5">
          <b-row>
            <b-img :src='require("@/assets/map-marker.png")' class="card-icon mb-2"></b-img>
            <h4 class="mb-3" style="text-align:left; color:#467EB7">Dirección</h4>
          </b-row>
          <b-card-text>Calle Paseo del bosque #2503 <br>
          Fraccionamiento Sicomoros<br>
          Chihuahua, Chihuahua</b-card-text>
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card bg-variant="light" class="text-center card mb-5">
          <b-row>
            <b-img :src='require("@/assets/phone.png")' class="card-icon mb-2"></b-img>
            <h4 class="mb-3" style="text-align:left; color:#467EB7">Teléfonos</h4>
          </b-row>
          <b-card-text><br>(614) 416 7989<br>          
          (614) 549 3284 </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card bg-variant="light" class="text-center card mb-5">
          <b-row>
            <b-img :src='require("@/assets/mail.png")' class="card-icon mb-2"></b-img>
            <h4 class="mb-3" style="text-align:left; color:#467EB7">Correo</h4>
          </b-row>
          <b-card-text><br><br>LeonardoMDEAC@outlook.com</b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable no-mixed-spaces-and-tabs */
// CREDS:
// GMAIL:   leonardoACMailer@gmail.com :: Leonardo un milagro de esperanza
// EMAILJS: leonardoACMailer@gmail.com :: Leonardo un milagro de esperanza

import emailjs from 'emailjs-com'
import { VueRecaptcha } from 'vue-recaptcha';

export default {    
  components: { VueRecaptcha },
  data()    {
    return {
    name: '',
    email: '',
    message:'',    
    recaptcha:false,
    infoWindow: {
            position: {
              lat: 28.63942199577297,
              lng: -106.09637048020218
            },
            open: false,
            template: ''
          },
          clicked: false        
    }
  },
  methods: {
    sendEmail(){
      const emailParams = {
        from_name: this.name,
        reply_to: this.email,
        message: this.message
      }
      try {
        if (this.name && this.message && this.email) {
          // this.disableSubmit = true;                           
          emailjs.send('service_ulzm7gw', 'template_mks8itr', emailParams,'9btI2nNwOCTLU3zsN') 
          alert("Mensaje enviado correctamente.")          
          this.$refs.recaptcha.reset();
        } else {
          alert("All fields required.");          
        }
      } catch (error) {
        console.log({ error });
      }   
    },
    onVerify(response) {
      console.log(this.name, this.message)
      this.recaptcha = true;
      return response;
    },
    openInfoWindowTemplate (pos) {
      this.infoWindow.position = pos
      this.infoWindow.open = true
      this.clicked = true
    }
  }
}
</script>

<style scoped>
.text{
  text-align:right;
}
.form{
  text-align:right;  
  background-color: transparent;
}
.card{
  border-radius:15px;
  border-color:transparent;  
}
.card-body{
  margin:10%
}
.card-text {
  text-align:left;
}
.card-icon{  
  width:50px;
}
#donations{
  margin-bottom:-48px;
  font-family: "Museo Slab";
    background: url('~@/assets/background.png') 20% 90% no-repeat;
  background-size: 50%;
}
#send{ 
  background-color:gold; 
  border-radius: 10px; 
  border-color:gold;
  width:25%;  
  font-family: "Grifa";
}
</style>
